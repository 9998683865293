import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { ComponentType, useCallback } from 'react'
import { PostThriveAudioResetProps } from '../../components/features/ThriveAudioReset/PostThriveAudioReset/PostThriveAudioReset'
import { ThriveResetAudio } from '../../graphql/generated/autogenerated'

export type PostThriveResetTrackableProps = PostThriveAudioResetProps

function withPostThriveAudioResetTracking(
    PostThriveAudioReset: ComponentType<PostThriveAudioResetProps>
) {
    return React.memo(function PostThriveAudioResetTrackable(
        props: PostThriveResetTrackableProps
    ) {
        const { onNext, ...restProps } = props

        const trackOnNext = useCallback((nextReset: ThriveResetAudio) => {
            Avo.promptSelected({
                featureType: 'content',
                activityType: 'reset_watch_next_selected',
                contentType: 'reset',
                displayText: null,
                contentFormatType: 'audio',
                contentId: nextReset?.id,
                contentTitle: nextReset?.name,
                contentSource: null,
                contentSubtype: null,
                contentUrl: null,
                isOnboarding: false,
                resetId: nextReset?.id,
                resetName: nextReset?.name,
                tabName: null,
                userId_: null,
                tstPlatform: null,
                checkInDate: null,
                isAutomaticCheckin: null,
                microstepBody: null,
                microstepId: null,
                microstepName: null,
                notificationCount: null
            })
        }, [])

        const onNextTrackable: PostThriveResetTrackableProps['onNext'] =
            useCallback(
                (...args) => {
                    const [nextThriveReset] = args
                    trackOnNext(nextThriveReset)

                    return onNext?.call(null, ...args)
                },
                [onNext, trackOnNext]
            )

        return <PostThriveAudioReset {...restProps} onNext={onNextTrackable} />
    })
}

export default withPostThriveAudioResetTracking
