import { Button, Card, CardMedia, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    playResetLabel: {
        defaultMessage: 'Play reset',
        description: 'Play reset label'
    }
})

export type PostResetNextResetPreviewProps = {
    thumbnail: string
    name: string
    description: string
    onClick: () => void
}

function PostResetNextResetPreview(
    props: PostResetNextResetPreviewProps
): JSX.Element {
    const { name, onClick, thumbnail, description } = props
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()

    const isMobileView = useMediaQuery(breakpoints.down('sm'))
    const isMobileLandscape = useMediaQuery('(max-height:500px)')

    return (
        <Card
            sx={{
                height: isMobileView ? 'inherit' : '100%',
                width: isMobileView ? '100%' : 'inherit'
            }}
        >
            <Stack
                direction={'row'}
                padding={2}
                gap={2}
                sx={
                    !isMobileView && {
                        height: '100%',
                        justifyContent: 'center'
                    }
                }
            >
                {thumbnail && (
                    <CardMedia
                        component="img"
                        sx={{
                            width:
                                !isMobileView && !isMobileLandscape ? 300 : 150
                        }}
                        image={thumbnail}
                        alt={description}
                    />
                )}

                <Stack
                    direction={'column'}
                    alignItems={'start'}
                    justifyContent={'space-between'}
                    gap={3}
                >
                    <Stack flex={'1 0 auto'} gap={1}>
                        <CoreTypography variant="h6">
                            <FormattedMessage
                                defaultMessage="Up next:"
                                description="Post thrive reset watch next title"
                            />
                        </CoreTypography>
                        <CoreTypography
                            data-testid="NextResetName"
                            variant="h5"
                        >
                            {name}
                        </CoreTypography>
                    </Stack>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClick}
                        aria-label={formatMessage(messages.playResetLabel)}
                        data-testid="WatchNextButton"
                        startIcon={<LeafIcon icon="play" fontSize="small" />}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="Play"
                                description="play button"
                            />
                        </CoreTypography>
                    </Button>
                </Stack>
            </Stack>
        </Card>
    )
}

export default React.memo(PostResetNextResetPreview)
