import { Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { AvoTypes } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ThriveReset } from '../../../../graphql/generated/autogenerated'
import withPostThriveResetActionsTracking from '../../../../tracking/withPostThriveResetActionsTracking'
import { PostResetNextResetPreview } from '../../../elements/PostResetScreen'
import PostThriveResetActions from './PostThriveResetActions'

const TrackablePostThriveResetActions = withPostThriveResetActionsTracking(
    PostThriveResetActions
)

export type PostThriveResetProps = {
    thriveReset: ThriveReset
    utmSource?: string
    resetType?: AvoTypes.ResetTypeValueType
    onNext?: (nextThriveReset: ThriveReset) => void
    onReplay?: () => void
    nextThriveReset: ThriveReset
}

function PostThriveReset(props: PostThriveResetProps): JSX.Element {
    const {
        thriveReset,
        utmSource,
        onNext,
        onReplay,
        nextThriveReset,
        resetType
    } = props

    const { breakpoints, spacing } = useTheme()
    const isMobileView = useMediaQuery(breakpoints.down('sm'))
    const isMobileLandscape = useMediaQuery('(max-height:500px)')

    const nextThriveResetThumbnail = useMemo(
        () =>
            nextThriveReset?.landscape?.thumbnail ??
            nextThriveReset?.portrait?.thumbnail,
        [nextThriveReset]
    )

    const handleNext = useCallback(
        () => onNext?.(nextThriveReset),
        [nextThriveReset, onNext]
    )

    return (
        <Stack
            alignItems="center"
            justifyContent={'center'}
            height={'100%'}
            width={'100%'}
            data-testid="PostThriveReset"
            gap={2}
            padding={2}
        >
            <Stack gap={2} alignItems={'center'} alignSelf={'center'}>
                <CoreTypography variant="h4" color="white">
                    <FormattedMessage
                        defaultMessage="Did you enjoy this Reset?"
                        description="post reset message"
                    />
                </CoreTypography>

                <TrackablePostThriveResetActions
                    thriveReset={thriveReset}
                    utmSource={utmSource}
                    onReplay={onReplay}
                    resetType={resetType}
                />
            </Stack>

            <Stack
                direction={isMobileView ? 'column' : 'row'}
                justifyContent={'center'}
                gap={2}
                alignItems={'center'}
                sx={
                    !isMobileLandscape &&
                    !isMobileView && {
                        position: 'absolute',
                        bottom: 20,
                        right: spacing(12)
                    }
                }
            >
                <PostResetNextResetPreview
                    data-testid="PostThriveReset-next-reset"
                    name={nextThriveReset?.name}
                    description={nextThriveReset?.description}
                    thumbnail={nextThriveResetThumbnail}
                    onClick={handleNext}
                />
            </Stack>
        </Stack>
    )
}

export default React.memo(PostThriveReset)
