import { Avo } from '@thriveglobal/thrive-web-tracking'
import {
    ComponentProps,
    ComponentType,
    ForwardedRef,
    forwardRef,
    memo,
    useCallback,
    useEffect
} from 'react'
import { PersonalizedResetPlayerNew } from '../../components/features/PersonalizedResetPlayer'
import { PersonalizedResetPlayerProps } from '../../components/features/PersonalizedResetPlayer/PersonalizedResetPlayerNew'
import { getPersonalizedResetAudioSegmentDuration } from '../../components/features/PersonalizedResetPlayer/utils'
import { getProgressInPercent } from '../../hooks/useAudioProgress/utils'
import useTrackOnce from '../useTrackOnce'
import PersonalizedResetPlayerRef from '../../components/features/PersonalizedReset/PersonalizedResetPlayerRef'

export type TrackablePersonalizedResetPlayerProps =
    PersonalizedResetPlayerProps & {
        utmSource?: string
    }

export const personalizedResetCompletePercentage = 80

export default function (
    PlayerComponent: ComponentType<
        ComponentProps<typeof PersonalizedResetPlayerNew>
    >
) {
    return memo(
        forwardRef<
            PersonalizedResetPlayerRef,
            TrackablePersonalizedResetPlayerProps
        >(function TrackablePersonalizedResetPlayer(
            props: TrackablePersonalizedResetPlayerProps,
            ref: ForwardedRef<PersonalizedResetPlayerRef>
        ) {
            const { personalizedReset, onTimeUpdate, ...restProps } = props

            useEffect(() => {
                if (personalizedReset?.id) {
                    Avo.contentStarted({
                        activityType: 'reset_started',
                        contentType: 'reset',
                        resetType: 'personal_reset',
                        featureType: 'content',
                        contentFormatType: 'video',
                        contentId: personalizedReset?.id,
                        contentUrl: null,
                        contentTitle: personalizedReset?.name,
                        contentSource: null,
                        contentSubtype: null,
                        challengeId: null,
                        dayNumber: null,
                        teamId: null,
                        teamType: null,
                        challengeTheme: null,
                        challengeType: null,
                        conversationId: null,
                        messageType: null,
                        transactionId: null,
                        tabName: null,
                        tstPlatform: null
                    })
                }
            }, [personalizedReset?.id, personalizedReset?.name])

            const trackPersonalizedResetEnd = useCallback(() => {
                Avo.contentCompleted({
                    activityType: 'reset_completed',
                    contentType: 'reset',
                    resetType: 'personal_reset',
                    featureType: 'content',
                    contentFormatType: 'video',
                    contentId: personalizedReset?.id,
                    contentUrl: null,
                    contentTitle: personalizedReset?.name,
                    contentSource: null,
                    contentSubtype: null,
                    challengeId: null,
                    dayNumber: null,
                    teamId: null,
                    teamType: null,
                    challengeTheme: null,
                    challengeType: null,
                    conversationId: null,
                    messageType: null,
                    transactionId: null,
                    tstPlatform: null
                })
            }, [personalizedReset?.id, personalizedReset?.name])

            const trackPersonalizedResetEndOnce = useTrackOnce(
                trackPersonalizedResetEnd,
                personalizedReset?.id
            )

            const onTimeUpdateTrackable: PersonalizedResetPlayerProps['onTimeUpdate'] =
                useCallback(
                    (time: number) => {
                        const currentTime = time

                        const audioSegmentDuration =
                            getPersonalizedResetAudioSegmentDuration(
                                personalizedReset
                            )

                        const progress = getProgressInPercent(
                            currentTime,
                            audioSegmentDuration
                        )

                        const hasVideoCompleted =
                            progress >= personalizedResetCompletePercentage

                        if (hasVideoCompleted) {
                            trackPersonalizedResetEndOnce()
                        }

                        onTimeUpdate?.(time)
                    },
                    [
                        onTimeUpdate,
                        personalizedReset,
                        trackPersonalizedResetEndOnce
                    ]
                )

            return (
                <PlayerComponent
                    ref={ref}
                    onTimeUpdate={onTimeUpdateTrackable}
                    personalizedReset={personalizedReset}
                    {...restProps}
                />
            )
        })
    )
}
