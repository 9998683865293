import React, { Ref, useCallback, useMemo } from 'react'
import {
    Button,
    ButtonProps,
    Tooltip,
    TooltipProps,
    useMediaQuery
} from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import isUndefined from 'lodash/isUndefined'

export type PostResetActionButtonProps = ButtonProps & {
    isPressed?: boolean
    isUpdating?: boolean
    tooltip?: string
}

function PostResetActionButton(
    props: PostResetActionButtonProps,
    ref: Ref<HTMLButtonElement>
): JSX.Element {
    const { isPressed, isUpdating, tooltip, disabled, children, sx, ...rest } =
        props
    const theme = useTheme()

    const canHoverElements = !useMediaQuery('(hover: none)')

    const buttonSx = useMemo(() => {
        return sx ?? {}
    }, [sx])

    const backgroundColor = useMemo(
        () =>
            isPressed
                ? theme.palette.secondary.dark
                : theme.palette.secondary.main,
        [isPressed, theme]
    )

    const Wrapper = useCallback(
        ({ children: wrappedChildren }: Partial<TooltipProps>) =>
            tooltip ? (
                <Tooltip
                    title={
                        <CoreTypography variant="caption">
                            {tooltip}
                        </CoreTypography>
                    }
                >
                    {wrappedChildren}
                </Tooltip>
            ) : (
                <>{wrappedChildren}</>
            ),
        [tooltip]
    )

    return (
        <Wrapper>
            <Button
                type="button"
                variant="contained"
                color="secondary"
                ref={ref}
                sx={{
                    backgroundColor: backgroundColor,
                    p: theme.spacing(1),
                    minWidth: '4.5rem',
                    minHeight: '4.5rem',
                    [theme.breakpoints.down('sm')]: {
                        p: 0,
                        minWidth: '3.5rem',
                        minHeight: '3.5rem'
                    },
                    ...(!canHoverElements
                        ? {
                              ':hover': {
                                  background: backgroundColor
                              }
                          }
                        : {}),
                    ...(isUpdating
                        ? {
                              ':disabled': {
                                  background: backgroundColor
                              }
                          }
                        : {}),
                    ...buttonSx
                }}
                disabled={isUpdating || disabled}
                {...(!isUndefined(isPressed)
                    ? { 'aria-pressed': isPressed }
                    : {})}
                {...rest}
            >
                {children}
            </Button>
        </Wrapper>
    )
}

export default React.memo(React.forwardRef(PostResetActionButton))
