import { Avo, AvoTypes } from '@thriveglobal/thrive-web-tracking'
import React, { ComponentType, useCallback } from 'react'
import { PostThriveResetActionsProps } from '../../components/features/ThriveReset/PostThriveReset/PostThriveResetActions'
import { useAppSelector } from '@thriveglobal/thrive-web-core'

export type PostThriveResetActionsTrackableProps =
    PostThriveResetActionsProps & {
        utmSource?: string
        resetType?: AvoTypes.ResetTypeValueType
    }

function withPostThriveResetActionsTracking(
    PostThriveResetActions: ComponentType<PostThriveResetActionsTrackableProps>
) {
    return React.memo(function PostThriveResetActionsTrackable(
        props: PostThriveResetActionsTrackableProps
    ) {
        const {
            utmSource,
            resetType,
            onClapped,
            onFavored,
            onShared,
            ...restProps
        } = props
        const { thriveReset } = props
        const user = useAppSelector((state) => state.user)

        const trackOnClapped = useCallback(
            (isChecked) => {
                if (isChecked) {
                    Avo.reactionSubmitted({
                        featureType: 'content',
                        activityType: 'clap_submitted',
                        contentType: 'reset',
                        contentFormatType: 'video',
                        contentId: thriveReset?.id,
                        contentTitle: thriveReset?.name,
                        contentUrl:
                            thriveReset?.landscape?.iframe ??
                            thriveReset?.portrait?.iframe,
                        resetType: resetType ?? 'reset',
                        contentSource: 'thrive',
                        feedbackAnswer: 'positive',
                        contentSubtype: null,
                        conversationId: null,
                        feedbackQuestion: null,
                        messageType: null,
                        openAnswer: null,
                        openQuestion: null,
                        tabName: null,
                        transactionId: null
                    })
                }
            },
            [
                thriveReset?.id,
                thriveReset?.landscape?.iframe,
                thriveReset?.name,
                thriveReset?.portrait?.iframe,
                resetType
            ]
        )

        const onClappedTrackable: PostThriveResetActionsProps['onClapped'] =
            useCallback(
                (...args) => {
                    trackOnClapped(...args)

                    return onClapped?.call(null, ...args)
                },
                [onClapped, trackOnClapped]
            )

        const trackOnFavored = useCallback(
            (isChecked) => {
                if (isChecked) {
                    Avo.reactionSubmitted({
                        featureType: 'content',
                        activityType: 'favorite_submitted',
                        contentType: 'reset',
                        contentFormatType: 'video',
                        contentId: thriveReset?.id,
                        contentTitle: thriveReset?.name,
                        contentUrl:
                            thriveReset?.landscape?.iframe ??
                            thriveReset?.portrait?.iframe,
                        resetType: resetType ?? 'reset',
                        contentSource: 'thrive',
                        feedbackAnswer: 'positive',
                        contentSubtype: null,
                        conversationId: null,
                        feedbackQuestion: null,
                        messageType: null,
                        openAnswer: null,
                        openQuestion: null,
                        tabName: null,
                        transactionId: null
                    })
                }
            },
            [
                thriveReset?.id,
                thriveReset?.landscape?.iframe,
                thriveReset?.name,
                thriveReset?.portrait?.iframe,
                resetType
            ]
        )

        const onFavoredTrackable: PostThriveResetActionsProps['onFavored'] =
            useCallback(
                (...args) => {
                    trackOnFavored(...args)

                    return onFavored?.call(null, ...args)
                },
                [onFavored, trackOnFavored]
            )

        const trackOnShared = useCallback(
            () =>
                Avo.promptSelected({
                    featureType: 'content',
                    activityType: 'share_reset_selected',
                    contentId: null,
                    contentType: 'reset',
                    contentTitle: null,
                    contentSubtype: null,
                    contentFormatType: null,
                    contentUrl: null,
                    contentSource: null,
                    resetId: thriveReset?.id,
                    resetName: thriveReset?.name,
                    isOnboarding: null,
                    microstepId: null,
                    microstepBody: null,
                    isAutomaticCheckin: null,
                    microstepName: null,
                    checkInDate: null,
                    userId_: user?.userId,
                    displayText: null,
                    tabName: null,
                    tstPlatform: null,
                    notificationCount: null
                }),
            [thriveReset?.id, thriveReset?.name, user?.userId]
        )

        const onSharedTrackable: PostThriveResetActionsProps['onShared'] =
            useCallback(
                (...args) => {
                    trackOnShared(...args)

                    return onShared?.call(null, ...args)
                },
                [onShared, trackOnShared]
            )

        return (
            <PostThriveResetActions
                {...restProps}
                onClapped={onClappedTrackable}
                onFavored={onFavoredTrackable}
                onShared={onSharedTrackable}
            />
        )
    })
}

export default withPostThriveResetActionsTracking
