import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { ThriveResetAudio } from '../../../../graphql/generated/autogenerated'
import { useGetNextRandomThriveAudioReset } from '../../../../hooks/useGetNextReset'
import withPostThriveAudioResetActionsTracking from '../../../../tracking/withPostThriveAudioResetActionsTracking'
import { PostResetNextResetPreview } from '../../../elements/PostResetScreen'
import PostThriveAudioResetActions from './PostThriveAudioResetActions'

const PostThriveAudioResetActionsTrackable =
    withPostThriveAudioResetActionsTracking(PostThriveAudioResetActions)

export type PostThriveAudioResetProps = {
    reset: ThriveResetAudio
    utmSource?: string
    onNext?: (nextReset: ThriveResetAudio) => void
    onReplay?: () => void
    onCancel?: () => void
}

function PostThriveAudioReset(props: PostThriveAudioResetProps): JSX.Element {
    const { reset, utmSource, onNext, onReplay } = props

    const { breakpoints, spacing } = useTheme()

    const isMobileView = useMediaQuery(breakpoints.down('sm'))
    const isMobileLandscape = useMediaQuery('(max-height:500px)')

    const [nextReset] = useGetNextRandomThriveAudioReset(reset)

    const nextResetThumbnail = nextReset?.images?.sm

    const handleNext = useCallback(
        () => onNext?.(nextReset),
        [nextReset, onNext]
    )

    return (
        <Stack
            alignItems="center"
            justifyContent={'center'}
            height={'100%'}
            width={'100%'}
            data-testid="PostThriveAudioReset"
            gap={2}
            padding={2}
        >
            <Stack gap={2} alignItems={'center'} alignSelf={'center'}>
                <CoreTypography variant="h4" color="white">
                    <FormattedMessage
                        defaultMessage="Did you enjoy this Reset?"
                        description="post reset message"
                    />
                </CoreTypography>

                <PostThriveAudioResetActionsTrackable
                    reset={reset}
                    utmSource={utmSource}
                    onReplay={onReplay}
                />
            </Stack>

            <Stack
                direction={isMobileView ? 'column' : 'row'}
                justifyContent={'center'}
                gap={2}
                alignItems={'center'}
                sx={
                    !isMobileLandscape &&
                    !isMobileView && {
                        position: 'absolute',
                        bottom: 20,
                        right: spacing(12)
                    }
                }
            >
                <PostResetNextResetPreview
                    name={nextReset?.name}
                    description={nextReset?.description}
                    thumbnail={nextResetThumbnail}
                    onClick={handleNext}
                />
            </Stack>
        </Stack>
    )
}

export default React.memo(PostThriveAudioReset)
