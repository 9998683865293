import { Stack, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { ThriveResetAudio } from '../../../../graphql/generated/autogenerated'
import { PostResetActionButton } from '../../../elements/PostResetScreen'
import { useThriveAudioResetLike } from '../../../../hooks/useResetLike'
import { useThriveAudioFavoriteReset } from '../../../../hooks/useResetFavorite'
import { Routes } from '../../../../routes/routes'
import { ThriveAudioResetParams } from '../../../../pages/ThriveAudioReset/types'
import { getShareableOrigin } from '../../../../utils/url'
import { copyTextToClipboard } from '../../../../utils/clipboard'
import { autoCloseCopyTooltipTimeout } from '../../../elements/ShareResetPopup/constants'
import { CopiedTooltipStyled } from '../../../elements/ShareResetPopup/ShareResetPopupStyled'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'

export type PostThriveAudioResetActionsProps = {
    reset: ThriveResetAudio
    onReplay?: () => void
    onShared?: () => void
    onFavored?: (isChecked: boolean) => void
    onClapped?: (isChecked: boolean) => void
}

const messages = defineMessages({
    replayAriaLabel: {
        defaultMessage: 'Replay',
        description: 'Post Reset replay button aria label'
    },
    favoriteAriaLabel: {
        defaultMessage: 'Favorite',
        description: 'Post Reset favorite button aria label'
    },
    clapAriaLabel: {
        defaultMessage: 'Clap',
        description: 'Post Reset clap button aria label'
    },
    shareAriaLabel: {
        defaultMessage: 'Share',
        description: 'Post Reset share button aria label'
    },
    copied: {
        defaultMessage: 'Copied!',
        description: 'Post Reset copy reset url to clipboard success message'
    }
})

function PostThriveResetActions(
    props: PostThriveAudioResetActionsProps
): JSX.Element {
    const {
        reset: thriveAudioReset,
        onReplay,
        onClapped,
        onFavored,
        onShared
    } = props

    const intl = useIntl()
    const theme = useTheme()

    const contentContainerRef = useRef<HTMLDivElement>(null)
    const copySuccessTooltipTimeout = useRef<NodeJS.Timeout | number>()
    const [isCopySuccessTooltipOpen, setIsCopySuccessTooltipOpen] =
        useState(false)

    const clearCopySuccessTimeout = useCallback(() => {
        if (copySuccessTooltipTimeout.current) {
            clearTimeout(copySuccessTooltipTimeout.current)
            copySuccessTooltipTimeout.current = null
        }
    }, [])

    const {
        createResetLike,
        deleteResetLike,
        isLoading: isLikeLoading
    } = useThriveAudioResetLike()

    const {
        createFavoriteReset,
        deleteFavoriteReset,
        isLoading: isFavoriteLoading
    } = useThriveAudioFavoriteReset()

    const isUpdating = isLikeLoading || isFavoriteLoading

    const isClapped = thriveAudioReset?.likes.isLiked ?? false
    const isFavorite = thriveAudioReset?.bookmarked ?? false
    const isInLibrary = location.pathname.startsWith(`${Routes.LearnReset}`)

    const resetUrl = useMemo(() => {
        if (!thriveAudioReset?.id) {
            return ''
        }

        const path = generatePath(
            isInLibrary
                ? `${Routes.Learn}${Routes.ThriveAudioReset}`
                : Routes.ThriveAudioReset,
            {
                id: thriveAudioReset.id
            } as ThriveAudioResetParams
        )
        const origin = getShareableOrigin()

        const url = new URL(path, origin)

        return url.toString()
    }, [isInLibrary, thriveAudioReset.id])

    const handleClapClick = useCallback(() => {
        if (isUpdating || !thriveAudioReset) {
            return
        }

        const isChecked = !thriveAudioReset.likes.isLiked
        if (isChecked) {
            createResetLike(thriveAudioReset)
        } else {
            deleteResetLike(thriveAudioReset)
        }

        onClapped?.(isChecked)
    }, [
        createResetLike,
        deleteResetLike,
        isUpdating,
        thriveAudioReset,
        onClapped
    ])

    const handleFavoriteClick = useCallback(() => {
        if (isUpdating || !thriveAudioReset) {
            return
        }

        const isChecked = !thriveAudioReset.bookmarked
        if (isChecked) {
            createFavoriteReset(thriveAudioReset)
        } else {
            deleteFavoriteReset(thriveAudioReset)
        }

        onFavored?.(isChecked)
    }, [
        createFavoriteReset,
        deleteFavoriteReset,
        isUpdating,
        thriveAudioReset,
        onFavored
    ])

    const handleShareClick = useCallback(async () => {
        clearCopySuccessTimeout()

        await copyTextToClipboard(resetUrl, {
            container: contentContainerRef.current
        })

        setIsCopySuccessTooltipOpen(true)

        copySuccessTooltipTimeout.current = setTimeout(() => {
            setIsCopySuccessTooltipOpen(false)
            clearCopySuccessTimeout()
        }, autoCloseCopyTooltipTimeout)

        onShared?.()
    }, [resetUrl, clearCopySuccessTimeout, onShared])

    useEffect(() => {
        return () => {
            clearCopySuccessTimeout()
        }
    }, [clearCopySuccessTimeout])

    return (
        <Stack
            direction="row"
            spacing={{ xs: 1, md: 2 }}
            ref={contentContainerRef}
            data-testid="PostThriveAudioResetActions"
        >
            <PostResetActionButton
                onClick={onReplay}
                aria-label={intl.formatMessage(messages.replayAriaLabel)}
                data-testid="ReplayButton"
            >
                <LeafIcon icon={'arrow-rotate-left'} />
            </PostResetActionButton>
            <PostResetActionButton
                isUpdating={isUpdating}
                isPressed={isFavorite}
                onClick={handleFavoriteClick}
                data-testid="FavoriteButton"
                aria-label={intl.formatMessage(messages.favoriteAriaLabel)}
            >
                <LeafIcon
                    icon={'heart'}
                    iconStyle={isFavorite ? 'solid' : 'regular'}
                />
            </PostResetActionButton>
            <PostResetActionButton
                isPressed={isClapped}
                isUpdating={isUpdating}
                onClick={handleClapClick}
                data-testid="ClapButton"
                aria-label={intl.formatMessage(messages.clapAriaLabel)}
            >
                <LeafIcon
                    icon={'hands-clapping'}
                    iconStyle={isClapped ? 'solid' : 'regular'}
                />
            </PostResetActionButton>

            <CopiedTooltipStyled
                open={isCopySuccessTooltipOpen}
                title={intl.formatMessage(messages.copied)}
                placement="top"
                sx={{ fontSize: theme.typography.body1.fontSize }}
                describeChild
            >
                <PostResetActionButton
                    onClick={handleShareClick}
                    aria-label={intl.formatMessage(messages.shareAriaLabel)}
                    data-testid="ShareButton"
                >
                    <LeafIcon icon={'share-nodes'} />
                </PostResetActionButton>
            </CopiedTooltipStyled>
        </Stack>
    )
}

export default React.memo(PostThriveResetActions)
